<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="title" custom-class="custom-dialog-max-width" @closed="form = null">
    <template v-if="form">
      <template v-if="editable">
        <el-form ref="form" :model="form" :rules="rules" label-width="84px" label-suffix=":" label-position="right">
          <div class="h" v-if="form.orderFormId">
            <el-form-item label="退单账户">
              <quick-select v-model="form.buyerErpId" filterable url="api/distributorErps/list"  placeholder="退单账户" value-field="erpId" display-field="name" style="width: 180px;" autoSelectFirstOption/>            
            </el-form-item>
            <el-form-item label="供应商">{{form.sellerName}}</el-form-item>
            <el-form-item label="采购单号">{{form.orderFormCode}}</el-form-item>
          </div>
          <div class="h s" v-else>
            <el-form-item label="下单账户">
              <quick-select v-model="form.buyerErpCode" :label.sync="form.buyerName" filterable url="api/distributorErps/list"  placeholder="下单账户" value-field="erpId" display-field="name" style="width: 180px;" autoSelectFirstOption/>            
            </el-form-item>            
            <el-form-item prop="sellerId" label="供应商">
              <quick-select v-model="form.sellerId" filterable url="api/purchase/seller" value-field="belongTo" display-field="belongToName" auto-select-first-option @change="form.items = []" style="width: 180px;" />
            </el-form-item>
            <el-form-item label-width="10px">
              <sku-selector v-if="form.sellerId" request-url="api/purchaseGoods" :request-params="{entId: form.sellerId}" :exclude-keys="excludeGoods" @submit="handleGoodsAdd" />
            </el-form-item>
          </div>

          <el-form-item label="退货详情">
            <el-table :data="form.items" class="table-outdent">
              <el-table-column prop="code" label="商品编码" min-width="130" />
              <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
              <el-table-column label="商品" min-width="240">
                <div style="line-height: 1.5;" slot-scope="scope">
                  <div>{{scope.row.goodsName}}</div>
                  <div class="fc-g">{{$goodsSpecConvert(scope.row.goodsSpec)}}</div>
                </div>
              </el-table-column>
              <template v-if="form.orderFormId">
                <el-table-column key="rel_realPrice" prop="realPrice" label="单价" width="100" align="right" :formatter="$price" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])"/>
                <el-table-column key="rel_count" prop="count" label="采购数量" width="100" align="center" />
                <el-table-column key="rel_receivedCount" prop="receivedCount" label="已收货数量" width="100" align="center" />
                <el-table-column key="rel_backCount" prop="_backCount" label="已退货数量" width="100" align="center" />
                <el-table-column key="rel_cancelCount" prop="_cancelCount" label="已取消数量" width="100" align="center" />
                <el-table-column key="rel_currentBackCount" label="本次退货" width="120">
                  <template slot-scope="scope">
                    <el-input-number v-model="scope.row._currentBackCount" :min="0.00" :max="scope.row._maxBackCount" :step="1" :precision="2" :controls="false" @change="$checkNaN(scope.row, '_currentBackCount',0)" style="width: 100%;" />
                  </template>
                </el-table-column>
              </template>
              <template v-else>
                <el-table-column key="no_realPrice" width="100" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
                  <template slot="header" slot-scope="scope">
                    <div class="h c lh-100">
                      <span>单价&nbsp;</span>
                      <el-tooltip content="该单价仅供参考，请以退货单审批后的单价为准！" placement="top">
                        <i class="fc-e el-icon-warning"></i>
                      </el-tooltip>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div v-if="scope.row._price.loading" class="fc-g">正在获取中…</div>
                    <div v-else-if="scope.row._price.error" class="h c">
                      <span class="fc-e">{{scope.row._price.error}}</span>
                      <a href="javascript:void(0)" @click="loadGoodsPrice(scope.row)">
                        &nbsp;
                        <i class="el-icon-refresh"></i>
                      </a>
                    </div>
                    <div v-else>
                      <b>{{$price(scope.row._price.value)}}</b>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column key="no_currentBackCount" label="本次退货" width="120">
                  <template slot-scope="scope">
                    <el-input-number v-model="scope.row._currentBackCount" :min="0.01" :max="9999" :step="1" :precision="2" style="width: 100%;" :controls="false" :disabled="scope.row._price.loading"  @change="$checkNaN(scope.row, '_currentBackCount',0.01, loadGoodsPrice)" />
                  </template>
                </el-table-column>
              </template>
              <el-table-column prop="info" label="备注" width="150">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.info" :maxlength="100" />
                </template>
              </el-table-column>
              <template v-if="!form.orderFormId">
                <el-table-column width="50">
                  <div class="row-commands" slot-scope="scope">
                    <el-button type="text" size="mini" @click="handleGoodsRemove(scope.row)">移除</el-button>
                  </div>
                </el-table-column>
              </template>
            </el-table>
          </el-form-item>

          <div class="h c">
            <el-form-item prop="senderName" label="发货人">
              <el-input v-model="form.senderName" :maxlength="50" style="width: 180px;" />
            </el-form-item>
            <el-form-item prop="senderPhone" label="联系电话" label-width="100px">
              <el-input v-model="form.senderPhone" :maxlength="25" style="width: 260px;" />
            </el-form-item>
            <el-form-item label-width="10px">
              <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/distributor/contact" empty-text="还没有常用收货人信息，可在“基础数据/收货信息”中添加。" @change="handleContactChange">
                <el-table-column prop="name" label="姓名" width="100" />
                <el-table-column prop="mobilePhone" label="手机号码" width="150" />
                <el-table-column prop="officePhone" label="办公室电话" width="150" />
              </datagrid-picker>
            </el-form-item>
          </div>
          <div class="h c">
            <el-form-item label="发货地址" prop="sendProvinceCode">
              <quick-select v-model="form.sendProvinceCode" :label.sync="form.sendProvince" url="api/regionalDict/province" value-field="code" placeholder="选择发货省份" filterable style="width: 180px;" @change="form.sendCityCode = null; form.sendDistrictCode = null;" />
            </el-form-item>
            <el-form-item label-width="12px" prop="sendCityCode" style="width: 180px;">
              <quick-select v-model="form.sendCityCode" :label.sync="form.sendCity" :url="`api/regionalDict/parent/${form.sendProvinceCode}`" value-field="code" placeholder="选择城市" filterable v-if="form.sendProvinceCode" style="width: 100%;" @change="form.sendDistrictCode = null" />
              <el-input disabled placeholder="请先选择发货城市" v-else />
            </el-form-item>
            <el-form-item label-width="12px" prop="sendDistrictCode" style="width: 180px;">
              <quick-select v-model="form.sendDistrictCode" :label.sync="form.sendDistrict" :url="`api/regionalDict/parent/${form.sendCityCode}`" value-field="code" placeholder="选择地区" filterable v-if="form.sendCityCode" style="width: 100%;" />
              <el-input disabled placeholder="请先选择发货地区" v-else />
            </el-form-item>
            <el-form-item label-width="12px" prop="sendAddress" class="flex">
              <el-input v-model="form.sendAddress" :maxlength="100" class="flex" placeholder="请输入详细地址" />
            </el-form-item>
            <el-form-item label-width="10px">
              <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/distributor/address" empty-text="还没有常用收货地址信息，可在“基础数据/收货信息”中添加。" @change="handleAddressChange">
                <el-table-column prop="provinceName" label="省" width="100" />
                <el-table-column prop="cityName" label="市" width="150" />
                <el-table-column prop="districtName" label="区" width="150" />
                <el-table-column prop="address" label="详细地址" width="300" />
              </datagrid-picker>
            </el-form-item>
          </div>

          <el-form-item prop="info" label="备注">
            <el-input type="textarea" v-model="form.info" :maxlength="200" placeholder="有其他要求或需要说明的情况，请在备注中说明" resize="none" :rows="2" />
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer" v-if="editable">
          <el-button type="text" @click="dialog = false">取消</el-button>
          <el-button :loading="saving" :disabled="submiting" type="primary" @click="doSave">保存</el-button>
          <el-button :loading="submiting" :disabled="saving" type="danger" @click="doSubmit" v-if="form.id">保存并提交</el-button>
        </div>
      </template>
      <template v-else>
        <el-form ref="form" :model="form" :rules="rules" label-width="76px" label-suffix="：" label-position="right">
          <div class="h sb">
            <div>
              <form-info-item label="退货账户">{{form.buyerName}}</form-info-item>
              <form-info-item label="退货单号">{{form.formCode}}</form-info-item>
              <form-info-item label="采购单号" v-if="form.orderFormId">{{form.orderFormCode}}</form-info-item>
              <form-info-item label="发货人">{{form.senderName}}（{{form.senderPhone}}）</form-info-item>
            </div>
            <div>
              <form-info-item label="退货时间">{{new Date(form.createAt).format()}}</form-info-item>
              <form-info-item label="供应商">{{form.sellerName}}</form-info-item>
              <form-info-item label="发货地址">{{form.sendProvince}}{{form.sendCity}}{{form.sendDistrict}}{{form.sendAddress}}</form-info-item>
            </div>
          </div>
          <el-table :data="backItems" border empty-text="当前退货单没有任何商品" style="margin: 8px 0;">
            <el-table-column prop="code" label="商品编码" min-width="130" />
            <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
            <el-table-column label="商品" min-width="240">
              <div style="line-height: 1.5;" slot-scope="scope">
                <div>{{scope.row.goodsName}}</div>
                <div class="fc-g">{{$goodsSpecConvert(scope.row.goodsSpec)}}</div>
              </div>
            </el-table-column>
            <el-table-column prop="realPrice" label="单价" width="100" align="right" :formatter="$price" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])"/>
            <template v-if="form.orderFormId">
              <el-table-column prop="count" label="采购数量" width="100" align="center" />
              <el-table-column prop="_backCount" label="已退货数量" width="100" align="center" />
              <el-table-column prop="_cancelCount" label="已取消数量" width="100" align="center" />
            </template>
            <el-table-column prop="_currentBackCount" label="本次退货" width="100" align="center" />
            <el-table-column prop="info" label="备注" min-width="150" />
          </el-table>
          <div class="h s" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
            <form-info-item class="flex" label="备注">{{form.info}}</form-info-item>
            <form-info-item label="退货金额" label-width="100px">
              <b v-if="form.orderFormId">{{$price(form.totalMoney || 0)}}</b>
              <b v-else>{{$price(totalMoney || 0)}}</b>
            </form-info-item>
            <form-info-item label="应退返利金" label-width="114px" v-if="form.rebateAmount">
              <b>{{$price(form.rebateAmount)}}</b>
            </form-info-item>
            <form-info-item label="实退现金" label-width="100px" v-if="form.realBackMoney">
              <b>{{$price(form.realBackMoney)}}</b>
            </form-info-item>
          </div>

          <div class="h c">
            <form-info-item label="当前状态">
              <dot v-if="form.backType==='fix'" same :type="repairStatus[form.status].type">{{repairStatus[form.status].label}}</dot>
              <dot v-else same :type="status[form.status].type">{{status[form.status].label}}</dot>
            </form-info-item>
            <template v-if="form.status >= 2">
              <form-info-item label="处理方式" label-width="120px" v-if="form.status !== 3">{{backTypes[form.backType]}}</form-info-item>
              <form-info-item label="处理意见" label-width="120px" class="flex">{{form.advice || "无"}}</form-info-item>
            </template>
          </div>
          <template v-if="action === 'outbound' && !form.isSend && form.status === 2">
            <el-divider content-position="left">退货出库</el-divider>
            <el-form ref="outboundForm" :model="outboundForm" :rules="rules" label-position="top">
              <div class="h e">
                <el-form-item prop="warehouseId" label="出库库房">
                  <quick-select v-model="outboundForm.warehouseId" filterable url="api/warehouse" auto-select-first-option placeholder="请选择出库库房" style="width: 240px;" />
                </el-form-item>
                <el-form-item style="margin-left: 20px;">
                  <el-button type="primary" :loading="submiting" @click="doOutbound">确认出库</el-button>
                  <el-button @click="dialog = false">取消</el-button>
                </el-form-item>
              </div>
            </el-form>
          </template>
        </el-form>
      </template>
    </template>
  </el-dialog>
</template>

<script>
import { add, get, edit, submit, outbound } from "@/api/purchaseBackForm";
import { getDefaultAddress, getDefaultContact } from "@/api/receiving";

import request from "@/utils/request";
import skuSelector from "@/views/assembly/skuSelect";
import { getPurchaseGoodsPrice } from "@/api/purchaseOrder";
import checkPermission from "@/utils/permission";

export default {
  components: { skuSelector },
  data() {
    return {
      loading: false,
      saving: false,
      submiting: false,
      dialog: false,
      form: null,
      action: null,
      defaultContact: null,
      defaultAddress: null,
      outboundForm: {
        id: null,
        warehouseId: null
      },
      backTypes: {
        returnMoney: "退货退款",
        fix: "维修"
      },
      repairStatus: [
        {
          type: "info",
          label: "编辑中"
        },
        {
          type: "warning",
          label: "待受理"
        },
        {
          type: "success",
          label: "已同意"
        },
        {
          type: "danger",
          label: "已拒绝"
        },
        {
          type: "success",
          label: "处理中"
        },
        {
          type: "primary",
          label: "维修已发出"
        },
        {
          type: "primary",
          label: "维修部分发出"
        },
        {
          type: "primary",
          label: "厂家已收货"
        }
      ],
      status: [
        {
          type: "info",
          label: "编辑中"
        },
        {
          type: "warning",
          label: "待受理"
        },
        {
          type: "success",
          label: "已同意"
        },
        {
          type: "danger",
          label: "已拒绝"
        },
        {
          type: "success",
          label: "处理中"
        },
        {
          type: "primary",
          label: "已完成"
        }
      ],
      rules: {
        sellerId: [{ required: true, message: "请选择供应商" }],
        sendProvinceCode: [{ required: true, message: "请选择省份" }],
        sendCityCode: [{ required: true, message: "请选择城市" }],
        sendDistrictCode: [{ required: true, message: "请选择地区" }],
        sendAddress: [{ required: true, message: "请填写详细地址" }],
        senderName: [{ required: true, message: "请填写发货人" }],
        senderPhone: [{ required: true, message: "请填写发货人联系电话" }],
        warehouseId: [
          { required: true, message: "请选择发货库房", trigger: "change" }
        ]
      }
    };
  },
  computed: {
    title() {
      let str = "加载中…";
      if (this.form) {
        return this.editable
          ? this.form.id
            ? "编辑退货单"
            : "新建退货单"
          : "查看退货单";
      }
      return str;
    },
    excludeGoods() {
      return (this.form.items || []).map(o => {
        return o.goodsId;
      });
    },
    editable() {
      return this.form && this.form.status === 0;
    },
    totalMoney() {
      let m = 0;
      if (this.form && this.form.items && this.form.items.length) {
        this.form.items.forEach(o => {
          m += o.realPrice * o._currentBackCount;
        });
      }
      return m;
    },
    backItems() {
      if (this.form && this.form.items && this.form.items.length) {
        return this.form.items.filter(o => {
          return o._currentBackCount > 0;
        });
      }
      return [];
    }
  },

  methods: {
    checkPermission,
    convertForm() {
      let form = null,
        items = [],
        count = 0;
      (this.form.items || []).forEach(o => {
        if (!o._currentBackCount) o._currentBackCount = 0;
        if (o._currentBackCount > 0) {
          count += o._currentBackCount;
          items.push({
            orderFormId: o.orderFormId,
            orderFormCode: o.orderFormCode,
            orderFormItemId: o.orderFormItemId,
            goodsId: o.goodsId,
            realPrice: o.realPrice,
            count: o._currentBackCount,
            info: o.info
          });
        }
      });
      if (count === 0) {
        this.$alert("请填写要退货的数量。", "系统提示");
      } else {
        form = JSON.parse(JSON.stringify(this.form));
        form.items = items;
      }
      return form;
    },
    doSave(callback) {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          let form = this.convertForm();
          if (form) {
            this.saving = true;
            (this.form.id ? edit : add)(form)
              .then(res => {
                if (res) this.form.id = res.id;
                this.$parent.init();
                this.saving = false;
                if (typeof callback === "function") callback();
                else
                  this.$notify({
                    title: "保存退货单成功",
                    type: "success",
                    duration: 2500
                  });
              })
              .finally(_ => {
                this.saving = false;
              });
          }
        });
    },
    doSubmit() {
      if (this.form && this.form.id) {
        this.$confirm(
          "提交退货单后将不能再进行修改，确定要提交吗？",
          "操作确认",
          {
            type: "warning",
            dangerouslyUseHTMLString: true
          }
        ).then(res => {
          this.doSave(_ => {
            this.submiting = true;
            submit({ id: this.form.id })
              .then(res => {
                this.$notify({
                  title: "提交退货单成功",
                  type: "success",
                  duration: 2500
                });
                this.dialog = false;
                this.$parent.init();
              })
              .finally(_ => {
                this.submiting = false;
              });
          });
        });
      }
    },
    doOutbound() {
      this.$refs.outboundForm &&
        this.$refs.outboundForm.validate().then(_ => {
          this.submiting = true;
          outbound(this.outboundForm)
            .then(_ => {
              this.$notify({
                title: "退货单出库成功",
                type: "success",
                duration: 2500
              });
              this.dialog = false;
              this.$parent.init();
            })
            .finally(_ => {
              this.submiting = false;
            });
        });
    },
    resetForm(form = null, order = null, action = null) {
      let ownId = null,
        sourceId = null;
      this.action = action;
      if (form) {
        ownId = form.id;
        this.outboundForm.id = form.id;
        sourceId = form.orderFormId;
      } else if (order) {
        sourceId = order.id;
      }
      if (sourceId) {
        this.loading = true;
        request({
          url: "api/orderBackForm/orderForm",
          method: "post",
          data: {
            orderFormId: sourceId,
            id: ownId
          }
        })
          .then(res => {
            res.items = (res.backItems || []).map(o => {
              let _no = {
                id: this.$uuid(),
                orderFormId: sourceId,
                orderFormCode: order ? order.formCode : form.orderFormCode,
                orderFormItemId: o.id,
                code: o.code,
                barcode: o.barcode,
                erpCode: o.erpCode,
                goodsId: o.goodsId,
                goodsSpec: o.goodsSpec,
                goodsName: o.goodsName,
                goodsBrandId: o.goodsBrandId,
                goodsBrandName: o.goodsBrand,
                goodsSeriesId: o.goodsSeriesId,
                goodsSeriesName: o.goodsSeries,
                goodsCategoryId: o.goodsCategoryId,
                goodsCategoryName: o.goodsCategoryName,
                price: o.price,
                realPrice: o.realPrice,
                count: o.count || 0,
                receivedCount: o.receivedCount || 0,
                info: "",
                _backCount: o.backCount || 0,
                _currentBackCount: o.currentBackCount || 0,
                _cancelCount: o.cancelCount || 0
              };
              _no._maxBackCount = _no.count - _no._backCount - _no._cancelCount;
              if (_no._maxBackCount < 0) _no._maxBackCount = 0;
              return _no;
            });
            delete res.backItems;
            if (order) {
              Object.assign(res, {
                buyerId: order.buyerId,
                buyerName: order.buyerName,
                sellerId: order.sellerId,
                sellerName: order.sellerName,
                orderFormId: order.id,
                orderFormCode: order.formCode,
                senderName: order.receiverName,
                senderPhone: order.receiverPhone,
                sendProvinceCode: order.recProvinceCode,
                sendProvince: order.recProvince,
                sendCityCode: order.recCityCode,
                sendCity: order.recCity,
                sendDistrictCode: order.recDistrictCode,
                sendDistrict: order.recDistrict,
                sendAddress: order.recAddress,
                status: 0,
                info: ""
              });
            }

            this.form = res;
          })
          .finally(_ => {
            this.loading = false;
          });
      } else {
        if (form && form.id) {
          this.loading = true;
          request({
            url: "api/orderBackForm/getById",
            method: "post",
            data: {
              id: form.id
            }
          })
            .then(res => {
              (res.backItems || []).forEach(o => {
                o._price = {
                  loading: false,
                  error: false,
                  value: o.realPrice
                };
                o._currentBackCount = o.currentBackCount;
              });
              res.items = res.backItems;
              delete res.backItems;
              this.form = res;
            })
            .finally(_ => {
              this.loading = false;
            });
        } else {
          this.form = Object.assign(
            {
              sellerId: null,
              sellerName: null,
              orderFormId: null,
              orderFormCode: null,
              senderName: null,
              senderPhone: null,
              sendProvinceCode: null,
              sendProvince: null,
              sendCityCode: null,
              sendCity: null,
              sendDistrictCode: null,
              sendDistrict: null,
              sendAddress: null,
              status: 0,
              info: "",
              items: []
            },
            this.defaultAddress
              ? {
                  sendProvinceCode: this.defaultAddress.provinceCode,
                  sendProvince: this.defaultAddress.provinceName,
                  sendCityCode: this.defaultAddress.cityCode,
                  sendCity: this.defaultAddress.cityName,
                  sendDistrictCode: this.defaultAddress.districtCode,
                  sendDistrict: this.defaultAddress.districtName,
                  sendAddress: this.defaultAddress.address
                }
              : {},
            this.defaultContact
              ? {
                  senderName: this.defaultContact.name,
                  senderPhone: this.defaultContact.phones
                }
              : {}
          );
        }
      }
      this.dialog = true;
    },
    loadGoodsPrice(g) {
      if (g && g.goodsId && !isNaN(g.count)){
        g._price.loading = true;
        g._price.error = false;
        g._price.value = null;
        g.realPrice = null;
        getPurchaseGoodsPrice({
          goodsId: g.goodsId,
          quantity: g.count,
          sellerId: this.form.sellerId
        })
          .then(res => {
            if (typeof res.goodsPrice !== "number") {
              g._price.error = "没有价格";
            } else {
              g.realPrice = res.goodsPrice;
              g._price.value = res.goodsPrice;
            }
            g._price.loading = false;
          })
          .catch(err => {
            g._price.error = "获取失败";
            g._price.loading = false;
          });
      }
    },
    handleGoodsAdd(list) {
      if (list && list.length) {
        this.form.items = (this.form.items || []).concat(
          list.map(o => {
            let _no = {
              id: this.$uuid(),
              orderFormId: null,
              orderFormCode: null,
              orderFormItemId: null,
              code: o.code,
              barcode: o.barcode,
              erpCode: o.erpCode,
              goodsId: o.id,
              goodsSpec: o.specs,
              goodsName: o.name,
              goodsBrandId: o.brandId,
              goodsBrandName: o.brandName,
              goodsSeriesId: o.seriesId,
              goodsSeriesName: o.seriesName,
              goodsCategoryId: o.categoryId,
              goodsCategoryName: o.categoryName,
              _price: {
                loading: false,
                error: false,
                value: null
              },
              realPrice: 0,
              info: "",
              _currentBackCount: 1
            };
            this.loadGoodsPrice(_no);
            return _no;
          })
        );
        this.$refs.form && this.$refs.form.clearValidate();
      }
    },
    handleGoodsRemove(row) {
      let inx = this.form.items.indexOf(row);
      if (inx >= 0) {
        this.form.items.splice(inx, 1);
      }
    },
    handleContactChange(ov, nv, row) {
      if (row) {
        let phones = [];
        if (row.mobilePhone) phones.push(row.mobilePhone);
        if (row.officePhone) phones.push(row.officePhone);

        this.form.senderName = row.name;
        this.form.senderPhone = phones.join(", ");
      }
    },
    handleAddressChange(ov, nv, row) {
      if (row) {
        Object.assign(this.form, {
          sendProvinceCode: row.provinceCode,
          sendProvince: row.provinceName,
          sendCityCode: row.cityCode,
          sendCity: row.cityName,
          sendDistrictCode: row.districtCode,
          sendDistrict: row.districtName,
          sendAddress: row.address
        });
      }
    }
  }
};
</script>
