import request from '@/utils/request'


export function add (data) {
  return request({
    url: 'api/purchaseBackForm',
    method: 'post',
    data
  })
}
export function audit (data) {
  return request({
    url: 'api/orderBackForm/check',
    method: 'post',
    data
  })
}
export function submit (data) {
  return request({
    url: 'api/orderBackForm/submit',
    method: 'post',
    data
  })
}
export function get (id) {
  return request({
    url: "api/orderBackForm/" + id,
    method: "get"
  });
}

export function edit (data) {
  return request({
    url: 'api/orderBackForm',
    method: 'put',
    data
  })
}

export function del (id) {
  return request({
    url: 'api/orderBackForm/' + id,
    method: 'delete'
  })
}

export function outbound (data) {
  return request({
    url: "api/orderBackForm/stockOut",
    method: "post",
    data
  })
}
